import {UserMunicipality} from '@shared/models/user-municipality.model';

export const VagaMunicipality: UserMunicipality = {
  name: 'vaga',
  slogan: {
    value: 'Aktiviteter og frivillige oppdrag - nær deg'
  },
  data: {},
  bannerURL: '',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  hasEquipment: false
}
