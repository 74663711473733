export const environment = {
  production: true,
  baseUrl: 'https://api.friskus.com/api/v1',
  googleMapKey: 'AIzaSyDltOmOTBCiHBRlKfeukaziheQ_fuBHUhA',
  uploadCareKey: '75274c495836cb13f6ca',
  activityTypeVolunteerUUID: '127806be-7f15-4b07-aa8b-bfc22261126d',
  isLangRedirectEnabled: true,
  apiUrl: {
    rss: 'https://rss.friskus.com/feed',
    event: 'https://api.friskus.com/api/v1',
    group: 'https://api.friskus.com/api/v1',
    identity: 'https://api.friskus.com/api/v1',
    user: 'https://api.friskus.com/api/v1',
    messenger: 'https://api.friskus.com/api/v1',
    card: 'https://api.friskus.com/api/v1',
    cardV2: 'https://api.friskus.com/api/v2',
    notifications: 'https://api.friskus.com/api/v1',
    tickets: 'https://api.friskus.com/api/v1',
  },
  backofficeUrl: 'https://admin.friskus.com',
  cards: {
    provider_id: 'c16d46e3-152d-4280-a622-64dee1f09593'
  },
  bugsnagKey: '029a2770fea541d218b1b2e22362b1cd',
  gtmId: '',
  defaultHasLeisureCard: false,
  hasSubsidiaryOrg: false,
  prefix: 'prod',
  news: false,
  clarityProjectId: ''
};
