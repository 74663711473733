import {UserMunicipality} from '@shared/models/user-municipality.model';

export const HadselMunicipality: UserMunicipality = {
  name: 'hadsel',
  slogan: {
    value: 'Aktiviteter og frivillige oppdrag - nær deg'
  },
  data: {},
  bannerURL: 'https://ucarecdn.com/741edd3e-c5b2-49d3-bf12-428552a6c520/Friskusbilde.jpg',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  hasEquipment: false,
}
